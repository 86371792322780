import React from 'react';
import { CardNavigation, CardSize } from '@naf/cards';
import { AdvancedImage } from '@cloudinary/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { nafColor } from '@nafcore/theme';
import { useCloudinary } from '../../../hooks/Cloudinary/useCloudinary';
import { MainEntryType } from '../../../../../types/siteStructureType';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';

type Props = {
  block: MainEntryType;
  size?: CardSize;
};

export const SingleNavigationCardBlock: React.FC<Props> = ({ block, size }) => {
  const { title, illustration, internalLink, externalLink, colorVariant, description } = block;
  const cld = useCloudinary();

  const itemImage = illustration?.publicId && cld.image(illustration.publicId).format('svg');

  return (
    <LinkComponent externalLink={externalLink} internalLink={internalLink}>
      <StyledCardNavigation
        title={title || internalLink?.name || ''}
        illustration={itemImage && <AdvancedImage cldImg={itemImage} alt={illustration?.altText || ''} />}
        size={size ?? CardSize.Small}
        colorVariant={colorVariant}
        isInternalLink
      >
        {description}
      </StyledCardNavigation>
    </LinkComponent>
  );
};

type LinkComponentProps = Pick<MainEntryType, 'externalLink' | 'internalLink'> & { children: React.ReactNode };

const LinkComponent = ({ externalLink, internalLink, children }: LinkComponentProps) => {
  const url = useDocumentUrlWithNode(internalLink) || '';

  if (url) {
    return <StyledLink to={url}>{children}</StyledLink>;
  }
  if (externalLink) {
    const { blank, href } = externalLink;
    return (
      <StyledA href={href} target={blank ? '_blank' : '_self'} rel={blank ? 'noreferrer' : ''}>
        {children}
      </StyledA>
    );
  }
  return <div>{children}</div>;
};

const StyledCardNavigation = styled(CardNavigation)`
  width: 100%;
  align-items: center;
  .card {
    margin: 3px !important;
  }
`;

const StyledA = styled.a`
  text-decoration: none;

  color: ${nafColor.signature.black} !important;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  color: ${nafColor.signature.black} !important;
`;
